import * as React from "react"
import { Link } from "gatsby"
import Direction from "../assets/images/direction.png"

// styles
const pageStyles = {
  color: "whitesmoke",
  background: "#009999",
  padding: "96px",
  fontFamily: "Montserrat Calibri sans-serif",
}

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <title>Not found</title>
      <div className="row">
        <div className="col-sm-6">
          <h1 className="mb-3">PAGE NON TROUVÉE</h1>
          <div
            className="mb-5"
            style={{ borderBottom: "1px solid whitesmoke" }}
          />
          <p>
            Désolé, cette page n'existe probablement pas.
            <br />
            <br />
            <Link style={{ color: "whitesmoke" }} to="/">
              Retour à l'accueil
            </Link>
          </p>
        </div>
        <div className="col-sm-6 col-12">
          <span
            style={{ fontSize: "150px", textAlign: "center", display: "block" }}
          >
            😶
          </span>
        </div>
      </div>
    </main>
  )
}

export default NotFoundPage
